var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticStyle:{"min-width":"1700px"}},[_c('b-row',[_c('b-col',[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"my-table","limit":"15"},model:{value:(_vm.curPage),callback:function ($$v) {_vm.curPage=$$v},expression:"curPage"}})],1)],1),_c('b-row',[_c('b-col',[_c('b-table',{staticStyle:{"width":"99%"},attrs:{"items":_vm.reportLog,"fields":_vm.fields,"per-page":_vm.perPage,"current-page":_vm.curPage,"show-empty":"","small":"","headVariant":"light","bordered":"","responsive":"","hover":"","busy":_vm.isLoading,"tbody-class":"acuvue-table","thead-class":"acuvue-thead"},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v(" Loading...")])],1)]},proxy:true},{key:"thead-top",fn:function(){return [_c('b-tr',[_c('b-th',{attrs:{"colspan":"5"}},[_vm._v("Всего с прибылью меньше 50руб. "+_vm._s(_vm.reportLog.length)+" артикулов")]),_c('b-th'),_c('b-th',{staticClass:"text-center",attrs:{"colspan":"4"}},[_vm._v("Цена закупочная")]),_c('b-th'),_c('b-th',{staticClass:"text-center",attrs:{"colspan":"5"}},[_vm._v("Цена на WB")]),_c('b-th')],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{attrs:{"role":"alert","aria-live":"polite"}},[_c('div',{staticClass:"text-center my-2"},[_vm._v("Список пуст.")])])]},proxy:true},{key:"cell(stocks)",fn:function(ref){
var item = ref.item;
return [_c('nobr',[_c('span',{attrs:{"title":"Склад джонсон в Москве"}},[_vm._v(_vm._s(item.stock.msk))]),_vm._v(" | "),_c('span',{attrs:{"title":"Склад джонсон в СПБ"}},[_vm._v(_vm._s(item.stock.spb))])])]}},{key:"cell(stocks_wb)",fn:function(ref){
var item = ref.item;
return [_c('nobr',[_c('span',{attrs:{"title":"FBO"}},[_vm._v(_vm._s(item.stock.wb_fbo))]),_vm._v(" | "),_c('span',{attrs:{"title":"FBS"}},[_vm._v(_vm._s(item.stock.wb_fbs))])])]}},{key:"cell(art_wb)",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://www.wildberries.ru/catalog/" + (item.art_wb) + "/detail.aspx?targetUrl=XS"),"target":"_blank"}},[_vm._v(_vm._s(item.art_wb))])]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"font-weight":"normal"},attrs:{"href":("https://www.wildberries.ru/catalog/" + (item.art_wb) + "/detail.aspx?targetUrl=XS"),"target":"_blank"}},[_vm._v(_vm._s(item.name.replace("Контактные линзы для глаз","").replace("Контактные линзы","")))])]}},{key:"cell(seller)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.seller.ogrn)+" "+_vm._s(item.seller.name)+" ")]}},{key:"cell(delta)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.delta))])]}},{key:"cell(komsa)",fn:function(ref){
var item = ref.item;
return [_c('nobr',[_c('span',[_vm._v(_vm._s(item.wb.komsaRub)+" ("+_vm._s(item.wb.komsaPercent)+")")])])]}},{key:"cell(wb)",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"#","id":("pop-history-" + (item.art_wb))}},[_c('nobr',[_c('span',[_vm._v(_vm._s(item.wb.onSite))]),_c('b-icon',{staticStyle:{"margin-left":"2px"},attrs:{"icon":"question-circle","aria-label":"Help","title":"История изменения цен"}})],1)],1),_c('b-popover',{ref:"popover",attrs:{"target":("pop-history-" + (item.art_wb)),"title":"История изменения цен"}},[_c('b-table-simple',{attrs:{"hover":"","small":"","caption-top":"","responsive":""}},[_c('b-thead',{attrs:{"head-variant":"dark"}},[_c('b-tr',[_c('b-th',[_vm._v("Дата")]),_c('b-th',[_vm._v("Новая цена")])],1)],1),_c('b-tbody',_vm._l((item.priceHistory),function(curPrice,indx){return _c('b-tr',{key:("ph-" + indx)},[_c('b-th',[_vm._v(_vm._s(curPrice.date))]),_c('b-th',{staticClass:"text-right"},[_vm._v(_vm._s(curPrice.price))])],1)}),1)],1)],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"my-table","limit":"15"},model:{value:(_vm.curPage),callback:function ($$v) {_vm.curPage=$$v},expression:"curPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }