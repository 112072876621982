<template>
  <b-row>
    <b-col style="min-width: 1700px">
<!--      <b-row>-->
<!--        <b-col md="auto">-->
<!--          <v-date-picker-->
<!--              v-model="range"-->
<!--              is-range-->
<!--              :masks="masks"-->
<!--              :columns="3"-->
<!--              locale="ru"-->
<!--              show-iso-weeknumbers-->
<!--              title-position="center"-->
<!--              mode="date"-->
<!--              :timezone="'UTC'"-->
<!--              is24hr-->
<!--          >-->
<!--          </v-date-picker>-->
<!--        </b-col>-->
<!--        <b-col>-->
<!--          Период: {{dateFormat(range)}}<br><br>-->
<!--          <a href="#" @click.prevent="filters.status='scanned'">Отсканированно:</a> <b>{{calcSumStats.scanned}} </b>({{calcSumStats.scannedErrors}} с ошибкой)<br>-->
<!--          <a href="#" @click.prevent="filters.status='addedPoints'">Начислено баллов:</a> <b>{{ calcSumStats.addedPoints || '?'}} </b>({{ calcSumStats.addedPointsQty }} покупателям) <br>-->
<!--          <a href="#" @click.prevent="filters.status='errors'">Статистика ошибок:</a> <br>-->
<!--          <div style="background-color: #e7e7e7;padding: 12px;width: 100%">-->
<!--            <div v-for="(se, indx) of calcSumStats.errorStats" :key="`stats-${indx}`">{{se.code}} - {{se.qty}}-->
<!--            </div>-->
<!--          </div>-->

<!--&lt;!&ndash;          <pre>USED_STICKER_ERROR - 3</pre>&ndash;&gt;-->
<!--&lt;!&ndash;          <pre>USER_NOT_FOUND_ERROR - 1</pre>&ndash;&gt;-->

<!--        </b-col>-->
<!--      </b-row> -->
      <b-row>
        <b-col>
          <b-pagination
              v-model="curPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="15"
          ></b-pagination>

        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
              :items="reportLog"
              :fields="fields"
              :per-page="perPage"
              :current-page="curPage"
              show-empty
              small
              headVariant="light"
              bordered
              responsive
              hover
              :busy="isLoading"
              tbody-class="acuvue-table"
              style="width: 99%"
              thead-class="acuvue-thead"
          >
            <template #table-busy>
              <div class="text-center text-secondary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>
            <template #thead-top="">
              <b-tr>
                <b-th colspan="5">Всего с прибылью меньше 50руб. {{reportLog.length}} артикулов</b-th>
                <b-th></b-th>
                <b-th colspan="4" class="text-center">Цена закупочная</b-th>
                <b-th></b-th>
                <b-th colspan="5" class="text-center">Цена на WB</b-th>
                <b-th></b-th>
              </b-tr>
            </template>
            <template #empty="">
              <div role="alert" aria-live="polite"><div class="text-center my-2">Список пуст.</div></div>
            </template>

            <template #cell(stocks)="{item}">
              <nobr>
              <span title="Склад джонсон в Москве">{{item.stock.msk}}</span> | <span title="Склад джонсон в СПБ">{{item.stock.spb}}</span>
            </nobr>
            </template>
            <template #cell(stocks_wb)="{item}">
              <nobr>
              <span title="FBO">{{item.stock.wb_fbo}}</span> | <span title="FBS">{{item.stock.wb_fbs}}</span>
            </nobr>
            </template>
            <template #cell(art_wb)="{item}">
              <a :href="`https://www.wildberries.ru/catalog/${item.art_wb}/detail.aspx?targetUrl=XS`" target="_blank">{{item.art_wb}}</a>
            </template>
            <template #cell(name)="{item}">
              <a :href="`https://www.wildberries.ru/catalog/${item.art_wb}/detail.aspx?targetUrl=XS`" target="_blank" style="font-weight: normal">{{item.name.replace("Контактные линзы для глаз","").replace("Контактные линзы","")}}</a>
            </template>
            <template #cell(seller)="{item}">
              {{item.seller.ogrn}} {{item.seller.name}}
            </template>
            <template #cell(delta)="{item}">
              <span style="font-weight: bold">{{item.delta}}</span>
            </template>
            <template #cell(komsa)="{item}">
              <nobr><span>{{item.wb.komsaRub}} ({{item.wb.komsaPercent}})</span></nobr>
            </template>
            <template #cell(wb)="{item}">
              <a href="#" style="" :id="`pop-history-${item.art_wb}`"><nobr>
              <span>{{item.wb.onSite}}</span>
              <b-icon icon="question-circle" aria-label="Help" style="margin-left: 2px" title="История изменения цен"></b-icon>
              </nobr></a>
              <b-popover ref="popover" :target="`pop-history-${item.art_wb}`" title="История изменения цен">
                <b-table-simple hover small caption-top responsive>
                <b-thead head-variant="dark">
                  <b-tr>
                    <b-th>Дата</b-th>
                    <b-th>Новая цена</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="(curPrice, indx) of item.priceHistory" :key="`ph-${indx}`">
                    <b-th>{{curPrice.date}}</b-th>
                    <b-th class="text-right">{{curPrice.price}}</b-th>
                  </b-tr>
                </b-tbody>
                </b-table-simple>
              </b-popover>

            </template>

<!--            <template #cell(price)="{item}">-->
<!--              {{item}}-->
<!--              <b-table-simple hover small caption-top responsive>-->
<!--              <b-thead head-variant="dark">-->
<!--                <b-tr>-->
<!--                  <b-th>офф прайс</b-th>-->
<!--                  <b-th>Скидка 10%</b-th>-->
<!--                  <b-th>Ретро 6%</b-th>-->
<!--                  <b-th>Маркетинг 4%</b-th>-->
<!--                  <b-th>Итого</b-th>-->
<!--                </b-tr>-->
<!--              </b-thead>-->
<!--              <b-tbody>-->
<!--                <b-tr>-->
<!--                  <b-th>{{item.price.offPrice}}</b-th>-->
<!--                  <b-th class="text-right">{{item.price.disc10}}</b-th>-->
<!--                  <b-td>{{item.price.retro6}}</b-td>-->
<!--                  <b-td>{{item.price.marketing}}</b-td>-->
<!--                  <b-td>{{item.price.finalPrice}}</b-td>-->
<!--                </b-tr>-->
<!--              </b-tbody>-->
<!--              </b-table-simple>-->
<!--            </template>-->



          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
              v-model="curPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="15"
          ></b-pagination>

        </b-col>
      </b-row>
    </b-col>

  </b-row>

</template>

<style>
.acuvue-thead th div {
  font-size: 12px;
}
</style>
<style scoped>
a {
  color: #111;
  font-weight: bold;
}
div {
  font-size: 12px;
}
</style>

<script>
import _ from "lodash"
// import dayjs from 'dayjs'
// import weekOfYear from "./node_modules/dayjs/plugin/weekOfYear.js"
// import './node_modules/dayjs/locale/ru.js'

export default {
  name: "acuvue-table-page",
  data() {
    return {
      reportLog: [],
      fields: [
        { key: 'stocks', label: 'МСК|СПб', sortable: false, sortDirection: 'desc' },
        { key: 'stocks_wb', label: 'Fbo|Fbs', sortable: false, sortDirection: 'desc' },
        { key: 'art_wb', label: 'Артикул', sortable: true, sortDirection: 'desc' },
        { key: 'name', label: 'Наименование', sortable: false, sortDirection: 'desc' },
        { key: 'seller', label: 'Продавец', sortable: false, sortDirection: 'desc' },
        { key: 'delta', label: 'Прибыль', sortable: true, sortDirection: 'desc' },
        { key: 'price.offPrice', label: 'Офф прайс', sortable: false, sortDirection: 'desc' },
        { key: 'price.disc10', label: 'Скидка 10%', sortable: false, sortDirection: 'desc' },
        { key: 'price.retro6', label: 'Ретро 6%', sortable: false, sortDirection: 'desc' },
        { key: 'price.marketing', label: 'Маркетинг 4%', sortable: false, sortDirection: 'desc' },
        { key: 'price.finalPrice', label: 'Итого', sortable: false, sortDirection: 'desc' },

        { key: 'wb', label: 'На сайте', sortable: true, sortDirection: 'desc' },
        { key: 'komsa', label: 'Комса', sortable: false, sortDirection: 'desc' },
        { key: 'wb.dostavka', label: 'Доставка', sortable: false, sortDirection: 'desc' },
        { key: 'wb.nalog', label: 'Налог 4%', sortable: false, sortDirection: 'desc' },
        { key: 'wb.upak', label: 'Упаковка', sortable: false, sortDirection: 'desc' },
        { key: 'wb.finalPrice', label: 'Доход', sortable: false, sortDirection: 'desc' },
      ],
      isLoading: false,
      curPage: 1,
      totalRows: 100,
      perPage: 50,

    };
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    this.getReport()
  },
  methods: {
    getReport() {
      this.isLoading=true
      fetch(`${process.env.VUE_APP_SERVER_API}/acuvue-table/getReport`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            console.log(resp)
            if (resp.status) {
              this.reportLog = _.orderBy(resp.items, 'delta', 'asc')
              this.isLoading=false
              this.totalRows=resp.items.length
            }
            // if (!resp.status) this.alertMsg('Ошибка загрузки выкупов', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    },

  },
  computed: {

  },
};
</script>